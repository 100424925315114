import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import $axios from '../tools/axiosWrapper'
import 'react-phone-number-input/style.css'
import CircularProgress from '@mui/material/CircularProgress'

export const Verify = ({ form, setPhone, setUser }) => {
  useEffect(() => {}, [])
  const [loading, setLoading] = useState(false)
  const [wrong, setWrong] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <div className={'flex flex-col items-center'}>
        <div className='flex items-center ml-4'>
          <div className='border-b border-b-2 border-b-slate-800 my-2 mr-6'>
            <input
              {...form.register('otp')}
              className='r w-full bg-main-100 font-noto font-regular text-main-300 text-2xl py-1 px-2 tracking-wide rounded-[10px]'
              autoFocus
              type='text'
              inputMode='numeric'
              maxLength={6}
              placeholder='code'
              onChange={(e) => {
                let otp = e.target.value
                form.setValue('otp', otp)
                if (otp.length === 6) {
                  setLoading(true)
                  console.log(form.getValues('phone_number'))
                  console.log(form.getValues('otp'))

                  $axios
                    .post('/auth/login/verify', {
                      phone_number: form.getValues('phone_number'),
                      otp: form.getValues('otp'),
                    })
                    .then((res) => {
                      form.setValue('state', res.data.state)
                      form.setValue('usersub', res?.data?.user?.sub)
                      setLoading(false)
                      // setPhone(res?.data?.user?.phone_number)

                      // $axios
                      //   .post('/auth/status', {
                      //     phone_number: res?.data?.user?.phone_number,
                      //   })
                      //   .then((res) => {
                      //     console.log(res)
                      //     setUser(res?.data?.response)
                      //   })
                      //   .catch((err) => {
                      //     console.log(err)
                      //   })
                    })
                    .catch((err) => {
                      console.log('err')
                      console.log(err)
                      setWrong(true)
                      setLoading(false)
                    })
                    .finally(() => {
                      $axios
                        .get('/voter/voter_info_get', {
                          params: {
                            usersub: form.getValues('usersub'),
                          },
                        })
                        .then((res) => {
                          _.isEmpty(res?.data)
                            ? navigate('/register')
                            : navigate('/profile')
                        })
                        .catch((err) => {
                          navigate('/register')
                          console.log('voter_info', err)
                        })
                    })
                }
              }}
            />
          </div>
        </div>
        <div className='text-red-500 text-sm'>
          {wrong ? 'Invalid Code' : ''}
        </div>
        <div className='w-full flex justify-between items-center'>
          <div className='px-4'>
            <CircularProgress
              className={`${loading ? 'visible' : 'invisible'}`}
              sx={{ color: '#18AA00' }}
            />
          </div>

          <button
            type='submit'
            className={`font-noto font-regular text-main-300 text-md rounded-[12px] m-1 p-5 py flex justify-center`}
            onClick={() => {
              form.setValue('otp', '')
              $axios
                .post('/auth/login', {
                  phone_number: form.getValues('phone_number'),
                })
                .then((res) => {
                  console.log(res)
                })
                .catch((err) => {
                  console.log(err)
                })
            }}
          >
            resend
          </button>
        </div>
      </div>
    </>
  )
}
