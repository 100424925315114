import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button } from '@mui/material'
import { Logo } from '../assets/logo'
import { Outlet, useNavigate } from 'react-router-dom'

import a from '../assets/imgs/pitl.svg'
import b from '../assets/imgs/newamerica.svg'
import c from '../assets/imgs/savetheworld.png'
import d from '../assets/imgs/dataprivacy.png'
import e from '../assets/imgs/mydatacan.png'
export const About = () => {
  const navigate = useNavigate()

  const LOGOS = [
    {
      title: 'PITL',
      img: a,
      link: 'https://techlab.org/',
      size: 'w-24',
    },
    {
      title: 'newamerica',
      img: b,
      link: 'https://www.newamerica.org/',
      size: 'w-24',
    },
    {
      title: 'savetheworld',
      img: c,
      link: '',
      size: 'w-28',
    },
    {
      title: 'dataprivacy',
      img: d,
      link: 'https://dataprivacylab.org/',
      size: 'w-28',
    },
    {
      title: 'mydatacan',
      img: e,
      link: 'https://mydatacan.org/',
      size: 'w-28',
    },
  ]
  const OPTIONS = [
    {
      title: 'VoteFlare™ notifies you if your voter information changes.',
      desc: 'You register and you vote and it is usually a smooth process. But sometimes unexpected things can happen --such as, database errors, accidental purges, or inappropriate or spurious changes. Any of these can keep your vote from counting on election day. If you know there is a problem before election day, you can often fix it. That’s where VoteFlare™ helps you. VoteFlare™ regularly checks official information from state and local governments and then texts you if your voter information changes. VoteFlare™ may give you the time you need to make corrections before election day. It is like credit monitoring but for your voter information.',
    },
    {
      title: 'You can identify VoteFlare™ messages.',
      desc: 'As the name implies, VoteFlare™ sends "flares" that warn you something changed. Our messages avoid impersonation because any messages from VoteFlare™ simply tells you that something changed and reminds you to visit voteflare.org for details. We never provide or ask for any personal information by email, text, or phone.',
    },
    {
      title: 'VoteFlare™ keeps your information safe.',
      desc: 'VoteFlare™ never changes any of your voter information. It merely reads information and reports to you if changes occur.',
    },
    {
      title: 'VoteFlare™ is independent of parties and campaigns.',
      desc: 'VoteFlare™ is not associated with any political party or campaign. It is a personal voter service. An organization may purchase coupons and give them to voters. But even in these cases, the communication still remains between VoteFlare™ and the voter.',
    },
    {
      desc: 'VoteFlare™ is a project from the Data Privacy Lab, in the Public Interest Technology Lab, at Harvard University.',
    },
  ]
  return (
    <div className='w-screen min-h-screen bg-main-100 flex justify-center'>
      <div className='w-[550px] flex flex-col my-20 mb-40'>
        <div>
          <div className='text-slate-100 text-3xl my-4'>Affiliates</div>
          <div className='bg-slate-200 p-8 flex flex-col gap-4 justify-between w-full h-[220px] rounded-[20px] grid-rows-2'>
            <div className={'flex items-center'}>
              {LOGOS.slice(0, 2).map((d) => (
                <div className='w-[200px]'>
                  <button className='p-2' onClick={() => navigate(d.link)}>
                    <img src={d.img} alt={d.title} className={d.size} />
                  </button>
                </div>
              ))}
            </div>
            <div className={'flex items-center '}>
              {LOGOS.slice(2, 5).map((d) => (
                <div className='w-[200px]'>
                  <button className='p-2' onClick={() => navigate(d.link)}>
                    <img src={d.img} alt={d.title} className={d.size} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        {OPTIONS.map((option, index) => (
          <>
            <div className='my-8 flex flex-col gap-4'>
              <div className='text-slate-100 text-3xl my-4'>{option.title}</div>
              <div className='text-slate-400 text-xl my-4'>{option.desc}</div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}
