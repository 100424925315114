import { useState, useEffect, useContext, useMemo } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import './input.css'
import { Main } from './main/main'
import { Head } from './head/head'
import $axios from './tools/axiosWrapper'
import { useLocalStorage } from 'usehooks-ts'
import { Developers } from './pages/developers'
import { Terms } from './pages/terms'
import { History } from './pages/history'
import { Home } from './home/home'

import { About } from './pages/about'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { set, useForm, useFormState } from 'react-hook-form'
import { Form } from './register/form'
import { Login } from './login/login'
import { Profile } from './profile/profile'
import { languages } from './context/languages'
import { Language } from './context/language'

export const App = () => {
  const location = useLocation()
  const [user, setUser] = useState('wait')
  const [phone, setPhone, removePhone] = useLocalStorage('vf-digit', 'a')
  const [language, set_language] = useState(languages[0])
  const value = useMemo(
    () => ({ language, set_language }),
    [language, set_language]
  )
  const form = useForm({})

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  useEffect(() => {
    if (location?.search) {
      const referalSite = location?.search.split('=')[1]
      $axios
        .post('/auth/referal', { referal: referalSite })
        .then((res) => {})
        .catch((err) => {})
    }
  }, [])

  useEffect(() => {
    if (phone !== '') {
      $axios
        .post('/auth/status', {
          phone_number: phone,
        })
        .then((res) => {
          const loggedIn =
            res?.data?.status == 'logged out' ? false : res?.data?.response
          setUser(loggedIn)
          form.setValue('usersub', res.data.response.Username)
        })
        .catch((err) => {
          setUser(false)
        })
    }
  }, [phone])

  useEffect(() => {
    user !== 'wait' &&
      user &&
      $axios
        .get('/voter/voter_info_get', {
          params: {
            usersub: user?.Username,
          },
        })
        .then((res) => {
          form.setValue('voterinfo', res?.data)
        })
        .catch((err) => {
          // console.log(err)
        })
  }, [user])

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Language.Provider value={value}>
          <CssBaseline />
          <Routes location={location} key={location.pathname}>
            <Route
              path={'/'}
              element={
                <Head
                  user={user}
                  setUser={setUser}
                  form={form}
                  removePhone={removePhone}
                />
              }
            >
              <Route index element={<Home />} />
              <Route
                path={'/main'}
                element={
                  <Main
                    setPhone={setPhone}
                    user={user}
                    setUser={setUser}
                    form={form}
                    removePhone={removePhone}
                  />
                }
              />
              <Route
                path={'/login'}
                element={
                  <Login
                    form={form}
                    user={user}
                    setUser={setUser}
                    setPhone={setPhone}
                  />
                }
              />

              <Route
                path={'/profile'}
                element={<Profile form={form} user={user} />}
              />

              <Route path={'/developers'} element={<Developers />} />
              <Route path={'/terms'} element={<Terms />} />
              <Route path={'/history'} element={<History />} />

              <Route path={'/about'} element={<About />} />
              <Route
                path={'/register'}
                element={
                  <Form
                    setPhone={setPhone}
                    user={user}
                    setUser={setUser}
                    form={form}
                  />
                }
              />
            </Route>
          </Routes>
        </Language.Provider>
      </ThemeProvider>
    </>
  )
}
