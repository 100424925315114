import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Foot } from './foot'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Logo } from '../assets/logoWhite'
import $axios from '../tools/axiosWrapper'
import { motion } from 'framer-motion'

export const Head = ({ user, setUser, form, removePhone }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <header className='w-screen flex flex-col bg-main-100 pr-2 sm:pr-8'>
        <div className='h-full w-full flex justify-end items-center p-2'>
          <div
            className={'p-2 ml-4 cursor-pointer flex'}
            onClick={() => navigate('/')}
          >
            <Logo width={40} />
            <div className='pl-3 mt-2 font-noto font-regular text-slate-100 text-[24px] font-semibold flex md:text-[30px]'>
              VoteFlare<div className='text-[20px] font-light'>&trade;</div>
            </div>
          </div>
          {/* <div className='text-slate-500 text-[18px] tracking-[1px] mx-3 mt-1 capitalize'>
            {location?.pathname.replace('/', '')}
          </div> */}
          <div className='h-full w-full flex justify-end items-center p-2'>
            {user && (
              <>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className='text-main-400 p-2 mr-2 border-2 px-4 border-main-400 rounded-full hover:bg-main-400 hover:text-white'
                  onClick={() => {
                    removePhone()

                    setUser(false)
                    form.setValue('step', 1)
                    navigate('/')

                    $axios
                      .post('/auth/logout')
                      .then((res) => {
                        console.log(res)
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                >
                  Logout
                </motion.button>
              </>
            )}
          </div>
        </div>
      </header>
      <Outlet />
      <Foot />
    </>
  )
}
