import _ from 'lodash'
import React, { useEffect } from 'react'

import { Search } from './3_search'
import { Select } from './4_select'
import { motion } from 'framer-motion'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import SearchIcon from '@mui/icons-material/Search'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'

export const Form = ({ user, setPhone, setUser, form }) => {
  useEffect(() => {
    form.setValue('step', 3)
  }, [])
  const onSubmit = (data) => {}

  const INSTRUCT = [
    {
      step: 1,
      p: 'Login or Signup with your phone number',
      icon: (s, c) => <PhoneIphoneIcon sx={{ fontSize: s, color: c }} />,
    },
    // {
    //   step: 2,
    //   p: 'Enter the 6-digit code we sent you',
    //   icon: (s, c) => <LockPersonIcon sx={{ fontSize: s, color: c }} />,
    // },
    {
      step: 3,
      p: 'Help us find your Voter Record',
      t: 'Search',
      icon: (s, c) => <SearchIcon sx={{ fontSize: s, color: c }} />,
    },
    {
      step: 4,
      p: 'Select which is correct',
      t: 'Confirm',
      icon: (s, c) => <ThumbUpAltIcon sx={{ fontSize: s, color: c }} />,
    },
  ]

  return (
    <>
      <main className='w-screen min-h-screen flex bg-main-100 justify-center'>
        <div className='w-screen min-h-screen max-w-lg min-h-[800px] flex bg-main-100 justify-center'>
          <div className='p-8'>
            <form onSubmit={form.handleSubmit(onSubmit)} className='pr-px'>
              <div className='mb-10 mt-4 w-[300px] h-[30px] pr-2'>
                <div className='font-noto font-bold flex justify-between'>
                  {INSTRUCT.map((d, i) => {
                    return (
                      <div
                        className={`w-1/3 h-[30px] flex items-center justify-around px-1`}
                      >
                        <div
                          className={`flex flex-row justfiy-center items-center w-full h-[10px] rounded-full ${
                            form.watch('step') > d.step
                              ? 'bg-green-900'
                              : 'bg-slate-900'
                          }`}
                        >
                          <div
                            className={`h-[30px] w-[30px] rounded-full bg-transparent flex items-center justify-center`}
                          >
                            <div>
                              {d.icon(
                                30,
                                form.watch('step') == d.step
                                  ? 'white'
                                  : form.watch('step') > d.step
                                  ? '#15803d'
                                  : '#475569'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className=' mb-8 font-noto text-slate-100 text-2xl'>
                <div className='font-bold'>
                  {INSTRUCT[form.watch('step') - 1]?.t}
                </div>
                <div className='text-slate-400 text-xl'>
                  {INSTRUCT[form.watch('step') - 1]?.p}
                </div>
              </div>
              <div
                className={
                  'w-full max-w-sm min-h-20 mt-2 rounded-[16px] border-2 border-orange'
                }
              >
                <div className={'flex flex-col items-center'}>
                  <div className={'p-1'}>
                    {form.watch('step') === 3 && (
                      <div className='animate-in'>
                        <Search user={user} form={form} />
                      </div>
                    )}
                    {form.watch('step') === 4 && (
                      <div className='animate-in'>
                        <Select user={user} form={form} />
                      </div>
                    )}
                    {/* {form.watch('step') === 5 && (
                      <div className='animate-in'>
                        <View user={user} form={form} />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </>
  )
}
